<template>
    <div>
        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{  $t('label_theme_styles') }}</b-breadcrumb-item>
            </div>
        </div>
        <b-col cols="12" md="12">
            <b-card

                    class="mb-0"
            >
                <b-card-header class="pb-50">
                    <h5>{{ $t('label_theme_styles') }}</h5>
                </b-card-header>

                <!--<color-picker :value="[]" @input="updateValue"></color-picker>-->

                <validation-observer
                        #default="{ handleSubmit, invalid }"
                        :ref="PREFIX + '_FORM'"
                        tag="form"
                        class="p-2"
                >

                    <b-row>
                        <b-col md="2" sm="12">

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_color')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_color')"
                                        class="text-limited"
                                >

                                    <color-picker  v-model="itemData.theme_bg_color"></color-picker>


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_active_els_color')"
                                    rules=""
                            >
                                <b-form-group
                                        :label="$t('label_theme_active_els_color')"
                                        class="text-limited"
                                >
                                    <color-picker  v-model="itemData.theme_color"></color-picker>

                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_font_color')"
                                    rules=""
                            >
                                <b-form-group
                                        :label="$t('label_theme_font_color')"
                                        class="text-limited"
                                >
                                    <color-picker  v-model="itemData.theme_font_color"></color-picker>

                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>



                        </b-col>

                        <b-col  md="2" sm="12">
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_page_bg_color')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_page_bg_color')"
                                        class="text-limited"
                                >

                                    <color-picker  v-model="itemData.theme_page_bg_color"></color-picker>


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_modal_bg_color')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_modal_bg_color')"
                                        class="text-limited"
                                >

                                    <color-picker  v-model="itemData.theme_modal_bg_color"></color-picker>


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_menu_bg_color')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_menu_bg_color')"
                                        class="text-limited"
                                >

                                    <color-picker  v-model="itemData.theme_menu_bg_color"></color-picker>


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col  md="4" sm="12">
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_font_size')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_font_size')"
                                        class=""
                                >

                                    <b-form-input

                                            v-model="itemData.theme_font_size"
                                            placeholder=""
                                            :state="getValidationState(validationProps)"
                                            trim
                                    />


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_thead_font_size')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_thead_font_size')"
                                        class=""
                                >

                                    <b-form-input

                                            v-model="itemData.theme_thead_font_size"
                                            placeholder=""
                                            :state="getValidationState(validationProps)"
                                            trim
                                    />


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_table_ceil_vert_pad')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_table_ceil_vert_pad')"
                                        class=""
                                >

                                    <b-form-input

                                            v-model="itemData.theme_table_ceil_vert_pad"
                                            placeholder=""
                                            :state="getValidationState(validationProps)"
                                            trim
                                    />


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_table_ceil_gor_pad')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_table_ceil_gor_pad')"
                                        class=""
                                >

                                    <b-form-input

                                            v-model="itemData.theme_table_ceil_gor_pad"
                                            placeholder=""
                                            :state="getValidationState(validationProps)"
                                            trim
                                    />


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                        </b-col>
                        <b-col md="4" sm="12">
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_table_ceil_font_size')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_table_ceil_font_size')"
                                        class=""
                                >

                                    <b-form-input

                                            v-model="itemData.theme_table_ceil_font_size"
                                            placeholder=""
                                            :state="getValidationState(validationProps)"
                                            trim
                                    />


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_menu_font_size')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_menu_font_size')"
                                        class=""
                                >

                                    <b-form-input

                                            v-model="itemData.theme_menu_font_size"
                                            placeholder=""
                                            :state="getValidationState(validationProps)"
                                            trim
                                    />


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_label_font_size')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_label_font_size')"
                                        class=""
                                >

                                    <b-form-input

                                            v-model="itemData.theme_label_font_size"
                                            placeholder=""
                                            :state="getValidationState(validationProps)"
                                            trim
                                    />


                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                    #default="validationProps"
                                    :name="$t('label_theme_font_family')"

                            >
                                <b-form-group
                                        :label="$t('label_theme_font_family')"
                                        class=""
                                >
                                    <v-select
                                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                            v-model="itemData.theme_font_family"
                                            :options="app_fonts "
                                            class="w-100"
                                            :placeholder="$t('label_select')"
                                            :reduce="val => val"

                                    >
                                        <template v-slot:option="option">

                                            <span class="" :style="{fontFamily:option.label}">{{ option.label }}</span>
                                        </template>
                                        <template v-slot:selected-option="option">

                                            <span class="" :style="{fontFamily:option.label}">{{ option.label }}</span>
                                        </template>
                                        <template v-slot:no-options="option">
                                            {{$t('label_no_items')}}
                                        </template>
                                    </v-select>

                                    <b-form-invalid-feedback>
                                        {{ validationProps.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                        </b-col>
                    </b-row>

                    <!--<b-alert  variant="info" show>-->
                        <!--<div class="">{{$t('message_change_password_period')}}</div>-->
                    <!--</b-alert>-->

                    <div class="d-flex mt-2 justify-content-end">
                        <b-button @click.prevent="handleSubmit(onSubmit);focusOnFormError(invalid)"
                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  variant="primary"
                                  class="mr-2"
                                  type="submit"
                        >
                            {{ action == 'editing'? $t('label_save') : $t('label_save') }}
                        </b-button>
                        <b-button
                                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                type="button"
                                variant="outline-secondary"
                                v-on:click="$router.go(-1)"
                        >
                            <!--@click="hide"-->
                            {{ $t('label_cancel') }}

                        </b-button>
                    </div>

                </validation-observer>

            </b-card>
        </b-col>
    </div>
</template>

<script>


    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import ColorPicker from '@src/views/components/colorpicker'
    import {
        BCardHeader, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BCard, BFormTextarea, BFormCheckbox,  BAlert,
    } from 'bootstrap-vue'

    import vSelect from 'vue-select'


    export default {
        name: "editItem.vue",
        components: {
            BCardHeader,
            BFormGroup,
            BFormInput,
            BFormInvalidFeedback,
            BButton, BCard,
            vSelect,
            BFormCheckbox,
            BFormTextarea,
            BAlert,

            ValidationProvider,
            ValidationObserver,
            'color-picker': ColorPicker

        },
        data() {
            return {

                PREFIX: 'theme',

                itemData: {},

                action: 'adding',
                blankItemData: {
                    theme_color: '',
                    theme_font_color: '',
                    theme_bg_color: '',
                    theme_page_bg_color:'',
                    theme_modal_bg_color:'',
                    theme_menu_bg_color:'',
                    theme_table_ceil_vert_pad:'',
                    theme_table_ceil_gor_pad:'',
                    theme_table_ceil_font_size:'',
                    theme_menu_font_size:'',
                    theme_thead_font_size:'',
                    theme_label_font_size:'',
                    theme_font_size: '',
                    theme_font_family: '',
                },
                app_fonts:[]

            }
        },
        created() {
            this.initData();
        },
        methods: {
            initData() {

                this.async('get', '/general_settings', {params:{}}, function (resp) {
                    let editedItem = resp.data;
                    let item = Object.assign({}, this.blankItemData);
                    // item.id = user.id;
                    for (let prop in item) {
                        if (editedItem.hasOwnProperty(prop)) {
                            item[prop] = editedItem[prop];
                        }
                    }
                    this.app_fonts = editedItem.app_fonts;
                    this.itemData = item;
                });

            },
            onSubmit() {
                let themeSettings = Object.assign({},this.itemData);
                for (let prop in themeSettings) {
                    if (themeSettings[prop].hex) {
                        themeSettings[prop] = themeSettings[prop].hex;
                    }
                }
                if (this.action == 'adding') {
                    this.async('post', '/general_settings/' + this.PREFIX, themeSettings, function (resp) {
                        // this.initData();
                        this.reload();
                    });
                }
            },
            // updateValue(e){
            //     // val = e.hex8
            //     console.log('rc',this.theme_color);
            // }
        }
    }
</script>




